$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;


@font-face {
   font-family: "Boxy";
   src: url(/assets/fonts/Boxy.otf);
}
body {
    margin: 0px;
}
body > header {
    background: bottom repeat-x url("/assets/images/header-background.png");
    background-size: 100px 493px;
    background-position-x: 0;
    height: 290px;
    width: 100%;
}

body > header img {
    margin-bottom: -25px;
    padding-left: 102.5px;
    padding-top: 61px;
    width: 95px;
    height: 110px;
    float: left;
}

body > header h1 {
    font: 1.7em "Times New Roman", serif;
    color: #FFFE56;
    padding: 0;
    margin: 0;
    align-self: flex-end;
    margin-bottom: -.6ex;
}

// body > header div::before {
//   display: inline-block;
//   height: 100%;
//   content: '';
// }

body > header div {
    transform: rotate(-30deg) skewX(-30deg);
    display: flex;
    height: 81px;
    width: 196px;
    align-items: end;
}

main .blog-post-presentation {
    display: flex;
    margin: 60px 50px 50px 100px;
    font-family: sans-serif;
    color: #444444;
}

main > .blog-post-presentation .timebox {
    width: 104px;
    height: 118px;
}

main .blog-post-presentation header h2 {
    margin-top: 0;
}

main .blog-post-presentation header time {
    font-style: italic;
}

main .blog-post-presentation article {
    padding-left: 54px;
    width: 50em;
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
}

body > header a {
    text-decoration: none;
}

a {
    color: #444444;
}
